
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript/wrapper.js';
  
  


  
  var importedApp = {};


  

    import { initState, initBind } from '@wix/yoshi-flow-editor/runtime/esm/velocycleMobx';
    import { observable } from 'mobx';
    var velocycleMobx = { initState, initBind, observable };
    


  

    var blocksControllerService = null;
    

  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
      DSN: 'https://f6291334b07e4e46a2b2617a0939ce1d@sentry-next.wixpress.com/2840',
      id: 'undefined',
      projectName: 'antonp-viewer-app-2',
      teamName: 'antonp',
      errorMonitor: true,
    };

  var experimentsConfig = {"scopes":["test"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/510f9e3e59c9e626/packages/viewer-apps/antonp-viewer-app-2/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/510f9e3e59c9e626/packages/viewer-apps/antonp-viewer-app-2/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    multilingualDisabled,
    projectName: "antonp-viewer-app-2",
    biConfig: null,
    appName: "Antonp Viewer App 2",
    appDefinitionId: "80d96e3d-ae80-424f-8529-41e2faa7772d",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: null,
          wrap: null,
          exports: null,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"scopes":["test"],"centralized":true},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/510f9e3e59c9e626/packages/viewer-apps/antonp-viewer-app-2/src/components/MyPage/controller.ts",
          appName: "Antonp Viewer App 2",
          appDefinitionId: "80d96e3d-ae80-424f-8529-41e2faa7772d",
          projectName: "antonp-viewer-app-2",
          componentName: "MyPage",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "f2fc225d-1998-41c9-82dc-751ba7349af3" }, { method: null,
          wrap: null,
          exports: null,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"scopes":["test"],"centralized":true},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/510f9e3e59c9e626/packages/viewer-apps/antonp-viewer-app-2/src/components/MyWidget/controller.ts",
          appName: "Antonp Viewer App 2",
          appDefinitionId: "80d96e3d-ae80-424f-8529-41e2faa7772d",
          projectName: "antonp-viewer-app-2",
          componentName: "MyWidget",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "991990a6-012f-4039-98b0-1f0c5ff87c58" }],
    false);

    export const createControllers = _createControllers
